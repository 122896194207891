import React,{useEffect, createContext, useState} from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import "./assets/sheets/main.scss";
import AppWrapper from "./template/appTemplate";
import PageLayout from "./pageLayout"
import AuthorizeApp from "./AuthorizeApp"
import { MyProvider } from './myContext'

import AwsExportConfig from "./aws-exports"
import { Auth, Amplify } from "aws-amplify"

Amplify.configure(AwsExportConfig())


function App() {

  const [userName, setUserName] = useState("")

  useEffect(() => {
    const getUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser()
        console.log("Pages: ", authenticatedUser)
        sessionStorage.setItem("Token", authenticatedUser.signInUserSession.accessToken.jwtToken)
        sessionStorage.setItem("organization",authenticatedUser.attributes['custom:organization'])
       
        setUserName(authenticatedUser.attributes.email)
      } catch {
        console.log("The user isn't signed in")

        setTimeout(() => {
          Auth.federatedSignIn({ customProvider: "SAIC" })
        }, 2000)
      }
    }

    getUser()

    RedirectExample()
    

  }, [])

  function RedirectExample() {
    window.location.replace('https://tenjin-prod.azurewebsites.net/')

  }
    
  return (
    <StyledEngineProvider injectFirst>
      <div className="appContainer">
        <AuthorizeApp >
          <MyProvider value={{ user: userName }}>
            <AppWrapper>
              <PageLayout />
            </AppWrapper>
          </MyProvider>
        </AuthorizeApp>
      </div>
    </StyledEngineProvider>
  );
}

export default App
