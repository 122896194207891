import React, {useState, useEffect, useContext} from "react"
import {
    Box,
    Tooltip,
    Avatar,
    Menu,
    MenuItem,
    Typography
} from "@mui/material/"
import MyContext from '../myContext'


function GenerateUserAvatar(props){

    const { user } = useContext(MyContext);

    const [anchorElUser, setAnchorElUser] = useState(null)

    
    const generateInitial = () => {
        let initial
        let userName = user === null ? "A" : user 
        initial = userName.substring(0,1)

        return initial

    }
       

    return (
        
        <Box style={{ textAlign: "right",float: 'right' }} >
            <Tooltip title={user === null ? "" : user } > 
            
                <Avatar className='gradientUserAvatar'  >

                    {generateInitial()}

                </Avatar>
    
             </Tooltip> 
            <Menu
            sx={{ mt: "50px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            >
          
                <MenuItem key={props.userName} >
                <Typography textAlign="center">{user === null ? "" : user }</Typography>
                </MenuItem>

            </Menu>
        </Box>
    )

}

function GenerateGPTAvatar(props){

    const [anchorElUser, setAnchorElUser] = useState(null)

    return (
        
        <Box >
            <Tooltip title={"Chat GPT"} > 
                <Avatar sx={{ fontSize: '12px'}} className='gradientChatAvatar'  >
                    Chat 
                </Avatar>
    
             </Tooltip> 
            <Menu
            sx={{ mt: "50px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            >
          
                <MenuItem key={"Chat GPT"} >
                <Typography textAlign="center">{"Chat GPT"}</Typography>
                </MenuItem>

            </Menu>
        </Box>
    )

}


export {
    GenerateUserAvatar,
    GenerateGPTAvatar
}