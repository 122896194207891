import React from "react"
import { 
  Grid
} from "@mui/material/"
import SearchBar from "./components/search"
import SAIC_LOGO from "./assets/images/Saic-Logo-Blue.png"

export default function PageLayout(props) {

  return (
    <Grid container xs={12} spacing={3} className="searchContainer">

      <Grid item xs={12}>
        <div className="marginCenter" style={{textAlign: 'center', marginTop: '250px'}}>
          <p style={{color: '#333',fontSize: '30px', opacity: '0.4' }}>Sorry, This site is temporarily unavailable</p>
          <img src={SAIC_LOGO} style={{width: '200px', opacity: '0.9'}} />
        </div>
       
        {/* <SearchBar /> */}
      </Grid>
 
    </Grid>
  )
}
