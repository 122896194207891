import React, { useEffect, useState } from "react"
import { AppBar, Toolbar, Container, Grid } from "@mui/material/"

import { useTheme } from "@mui/material/styles"
import Tenjin from "../assets/images/tenjinLogo.png"
import { GenerateUserAvatar } from "../tools/avatarGenerator"

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)


  const themedStyles = (theme) => {
    return {
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
      },
    }
  }

  const theme = useTheme();

  return (
    <AppBar position="fixed" sx={{ ...themedStyles(theme).appBar }}>
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <Grid container xs={12}>
            <Grid item xs={5}>
              <a href={"/"}>
                <img
                  style={{
                    height: "60px",
                    marginTop: "3px",
                    marginLeft: "25px",
                  }}
                  src={Tenjin}
                />
              </a>
            </Grid>

            <Grid item xs={5} />

            {/* Avatar */}
            <Grid
              item
              xs={2}
              style={{ textAlign: "right", float: "right", marginTop: "10px" }}
            >
              <GenerateUserAvatar />
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
